module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://highspeed.centurylink.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"chatEnabled":true,"analyticsId":"GTM-M3L7JNQ","brandTokens":["CTL","TND"],"mapiBrandToken":"EMB","siteName":"highspeed.centurylink","alternateName":"highspeed.centurylink.com","siteURL":"https://highspeed.centurylink.com","defaultTitleTemplate":"","defaultPhone":"8556568510","phoneSymbol":"-","defaultPromoCode":"126380","smartyStreetsWebsiteKey":"17515623118088282","addressQualificationCode":"clearlink-ctl","convertProjectID":"10045794","vwoProjectID":"894940","cobra":{"sitePath":"highspeed.centurylink.com","buttonLink":"/cart","buttonText":"Order Online","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"highspeed.centurylink","short_name":"highspeed.centurylink","start_url":"/","background_color":"#48D597","theme_color":"#48D597","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"28a6ace9291e87b01d77ad9c07486849"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
