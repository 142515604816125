import { withTheme } from '@emotion/react'
import React from 'react'
import ButtonStyles from './Button.styles'
import ChatStyles from './Chat.styles'
import MegaFormStyles from './MegaForm.styles'

const GlobalStyles = withTheme(() => (
  <>
    <ButtonStyles />
    <MegaFormStyles />
    <ChatStyles />
  </>
))

export default GlobalStyles
